import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBookingRegistry, LazyBookingRegistryConfirm, LazyBookingRegistryEdit, LazyBookingRegistryEditBuyer, LazyTooManyBookingRequestDialog, LazyBookingCard, LazyBookingList, LazyBookingSummary, LazyBookingSummaryCard, LazyBookingSummaryInfo, LazyBookingSteps, LazyBookingStepsRealty, LazyBookingOffer, LazyBookingPayment, LazyBookingPersonal, LazyBookingFinishing, LazyBookingFinishingCard, LazyBookingMortgageProgramFilter, LazyBookingMortgageProgramList, LazyBookingMortgagePrograms, LazyBookingPurchaseConditions, LazyPurchaseConditionItem, LazyHeader, LazyMortgageCard, LazyMortgageCreate, LazyMortgageDvizhLogin, LazyRealtyList, LazyAcceptanceDialogRealty, LazyAcceptanceDialogRealtyOwners, LazyAcceptanceDialogSms, LazyAcceptances, LazyHouseFilter, LazyHouseGrid, LazyImportRealtyExcelButton, LazyRealtyFilter, LazyRealtyGrid, LazyMonitoring, LazyMonitoringDay, LazyMonitoringMonth, LazyMonitoringPlanning, LazyMonitoringWeek, LazyCalendarAcceptance, LazyDayCalendar, LazyMonitoringFilters, LazyMonitoringMonthInput, LazyMonitoringWeekInput, LazySettlementCard, LazySettlementList, LazySettlementSteps, LazyAppointment, LazyAcceptanceCompleted, LazyAcceptanceScheduled, LazyAcceptanceScheduledCancel, LazyAcceptanceScheduledSuccess, LazyDate, LazyDateSuccessDialog, LazyPersonalData, LazyPersonalDataOwner } from '#components'
const lazyGlobalComponents = [
  ["BookingRegistry", LazyBookingRegistry],
["BookingRegistryConfirm", LazyBookingRegistryConfirm],
["BookingRegistryEdit", LazyBookingRegistryEdit],
["BookingRegistryEditBuyer", LazyBookingRegistryEditBuyer],
["TooManyBookingRequestDialog", LazyTooManyBookingRequestDialog],
["BookingCard", LazyBookingCard],
["BookingList", LazyBookingList],
["BookingSummary", LazyBookingSummary],
["BookingSummaryCard", LazyBookingSummaryCard],
["BookingSummaryInfo", LazyBookingSummaryInfo],
["BookingSteps", LazyBookingSteps],
["BookingStepsRealty", LazyBookingStepsRealty],
["BookingOffer", LazyBookingOffer],
["BookingPayment", LazyBookingPayment],
["BookingPersonal", LazyBookingPersonal],
["BookingFinishing", LazyBookingFinishing],
["BookingFinishingCard", LazyBookingFinishingCard],
["BookingMortgageProgramFilter", LazyBookingMortgageProgramFilter],
["BookingMortgageProgramList", LazyBookingMortgageProgramList],
["BookingMortgagePrograms", LazyBookingMortgagePrograms],
["BookingPurchaseConditions", LazyBookingPurchaseConditions],
["PurchaseConditionItem", LazyPurchaseConditionItem],
["Header", LazyHeader],
["MortgageCard", LazyMortgageCard],
["MortgageCreate", LazyMortgageCreate],
["MortgageDvizhLogin", LazyMortgageDvizhLogin],
["RealtyList", LazyRealtyList],
["AcceptanceDialogRealty", LazyAcceptanceDialogRealty],
["AcceptanceDialogRealtyOwners", LazyAcceptanceDialogRealtyOwners],
["AcceptanceDialogSms", LazyAcceptanceDialogSms],
["Acceptances", LazyAcceptances],
["HouseFilter", LazyHouseFilter],
["HouseGrid", LazyHouseGrid],
["ImportRealtyExcelButton", LazyImportRealtyExcelButton],
["RealtyFilter", LazyRealtyFilter],
["RealtyGrid", LazyRealtyGrid],
["Monitoring", LazyMonitoring],
["MonitoringDay", LazyMonitoringDay],
["MonitoringMonth", LazyMonitoringMonth],
["MonitoringPlanning", LazyMonitoringPlanning],
["MonitoringWeek", LazyMonitoringWeek],
["CalendarAcceptance", LazyCalendarAcceptance],
["DayCalendar", LazyDayCalendar],
["MonitoringFilters", LazyMonitoringFilters],
["MonitoringMonthInput", LazyMonitoringMonthInput],
["MonitoringWeekInput", LazyMonitoringWeekInput],
["SettlementCard", LazySettlementCard],
["SettlementList", LazySettlementList],
["SettlementSteps", LazySettlementSteps],
["Appointment", LazyAppointment],
["AcceptanceCompleted", LazyAcceptanceCompleted],
["AcceptanceScheduled", LazyAcceptanceScheduled],
["AcceptanceScheduledCancel", LazyAcceptanceScheduledCancel],
["AcceptanceScheduledSuccess", LazyAcceptanceScheduledSuccess],
["Date", LazyDate],
["DateSuccessDialog", LazyDateSuccessDialog],
["PersonalData", LazyPersonalData],
["PersonalDataOwner", LazyPersonalDataOwner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
